@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/corner-radius';
@use '@sats-group/ui-lib/tokens/light';

.edit-phone-number {
  &__fieldset-wrapper {
    background: light.$surface-primary-default;
    border-radius: corner-radius.$s;
    padding: spacing.$m;
    margin-bottom: spacing.$l;
  }

  &__fieldset {
    border: 0;
    margin: 0;
    padding: 0;
  }

  &__descriptive-header {
    margin-bottom: spacing.$m;
  }

  &__input {
    max-width: 600px;
    margin-bottom: spacing.$l;
  }

  &__fields {
    display: flex;
    flex-direction: column;
    gap: spacing.$s;

    @media (min-width: 600px) {
      flex-direction: row;
      align-items: flex-end;
    }
  }

  &__buttons {
    >*+* {
      margin-left: spacing.$xs;
    }
  }
}

